import React from "react"
import "./Card.scss"

const Card = ({ number, text }) => {
  return (
    <div className="col">
      <div className="card d-flex py-5 px-6 gap-5 align-items-center">
        <div className="card__number">{number}</div>
        <div className="card__text">{text}</div>
      </div>
    </div>
  )
}

export default Card
