import * as React from "react"
import "../scss/MockupsProPage.scss"
import Seo from "../components/seo/seo"

import Card from "../shared/card/Card"
import Header from "../components/header/header"
import { navigate } from "gatsby-link"
import Footer from "../components/footer/footer"

import { StaticImage } from "gatsby-plugin-image"

const MockupsPro = props => {
  const staticContents = props.pageContext.staticContent
  const menu = props.pageContext.menu
  const staticHeaderPage = props.pageContext.staticHeaderPage

  return (
    <div>
      <div className="container">
        <Header
          menu={menu}
          siteTitle={staticContents["<title>"]}
          contents={staticHeaderPage}
          displayLogoLinks={false}
        />
        <Seo
          keywordsContent={staticContents["<meta> keywords"]}
          descriptionContent={staticContents["<meta> description"]}
          title={staticContents["<title>"]}
        />
        <div className="mockups-pro mt-4">
          <div className="row mb-4">
            <h1
              className="col"
              dangerouslySetInnerHTML={{
                __html: staticContents["Page Header"],
              }}
            />
          </div>
          <StaticImage
            src="../images/mockupsProPage.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Mockups pro"
            className="mockups-pro-page mb-4"
          />
          <div className="row">
            <h3 className="col">{staticContents["Page Header Details"]}</h3>
          </div>
          <div className="row mt-6">
            <h1
              className="col"
              dangerouslySetInnerHTML={{
                __html:
                  staticContents["Page Promo Row 1"] +
                  " " +
                  staticContents["Page Promo Row 2"] +
                  " " +
                  staticContents["Page Promo Row 3"] +
                  " " +
                  staticContents["Page Promo Row 4"] +
                  " " +
                  staticContents["Page Promo Row 5"],
              }}
            />
          </div>

          <div className="row mt-4">
            <h3 className="col">{staticContents["Page Promo Text"]}</h3>
          </div>
          <div className="special-padding mt-4 mb-6">
            <button
              type="button"
              onClick={() => navigate("https://www.bigmedia.ua/mockups/")}
              className="btn btn-primary btn-lg col"
            >
              {staticContents["Button Text"]}
            </button>
            <div className="col-lg-7"></div>
          </div>

          {/* Cards section */}
          <div className="row">
            <h1 className="col mt-3 mb-2">
              {staticContents["Page Subpromo Row 1"]}&nbsp;
              {staticContents["Page Subpromo Row 2"]}
            </h1>
          </div>

          <div className="row mb-3 mt-4">
            <Card number={1} text={staticContents["Subpromo Highlights 1"]} />
          </div>
          <div className="row mb-3">
            <Card number={2} text={staticContents["Subpromo Highlights 2"]} />
          </div>
          <div className="row mb-5">
            <Card number={3} text={staticContents["Subpromo Highlights 3"]} />
          </div>
          <div className="row mt-7">
            <h3 className="col">{staticContents["Subpromo Text"]}</h3>
          </div>

          <div className="row mt-6">
            <h1
              className="col"
              dangerouslySetInnerHTML={{
                __html:
                  staticContents["Subpromo zFooter Row 1"] +
                  " " +
                  staticContents["Subpromo zFooter Row 2"] +
                  " " +
                  staticContents["Subpromo zFooter Row 3"],
              }}
            />
          </div>

          <div className="mt-4 mb-6">
            <button
              type="button"
              onClick={() => navigate(staticContents["Button Link"])}
              className="btn btn-primary btn-lg col"
            >
              {staticContents["Button Text"]}
            </button>
            <div className="col-lg-7"></div>
          </div>
        </div>
      </div>

      <Footer
        recaptchaSettings={props.pageContext.recaptchaSettings}
        staticContactsForm={props.pageContext.staticContactsForm}
        staticContactsPage={props.pageContext.staticContactsPage}
      />
    </div>
  )
}

export default MockupsPro
